import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import * as Sentry from "@sentry/react";
import { useQueryClient, useQueryErrorResetBoundary } from "@tanstack/react-query";

import Button from "./Button";

const QueryErrorBoundary = ({ children }: { children: JSX.Element }) => {
  const { reset } = useQueryErrorResetBoundary();
  const queryClient = useQueryClient();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <div className="relative px-4 py-3 m-4 text-red-700 bg-red-100 rounded border border-red-400" role="alert">
          <strong className="block font-bold">Holy smokes!</strong>
          <span className="block sm:inline">Something bad happened: {error.message}</span>
          <div className="my-2">
            <Button
              color="secondary"
              onClick={() => {
                resetError();
                queryClient.clear();
              }}
            >
              Try again
            </Button>
          </div>
        </div>
      )}
    >
      <ReactErrorBoundary
        onReset={reset}
        fallbackRender={({ resetErrorBoundary, error }) => (
          <div className="relative px-4 py-3 m-4 text-red-700 bg-red-100 rounded border border-red-400" role="alert">
            <strong className="block font-bold">Holy smokes!</strong>
            <span className="block sm:inline">Something bad happened: {error.message}</span>
            <div className="my-2">
              <Button
                color="secondary"
                onClick={() => {
                  resetErrorBoundary();
                  queryClient.clear();
                }}
              >
                Try again
              </Button>
            </div>
          </div>
        )}
      >
        {children}
      </ReactErrorBoundary>
    </Sentry.ErrorBoundary>
  );
};

export default QueryErrorBoundary;
