// utility functions. If this file gonna grow, we may need switching into lodash or something instead.
import { isNil } from "lodash-es";
import { DateTime } from "luxon";

export function isValidDateString(value: string, format?: string) {
  const dateObject = format ? DateTime.fromFormat(value, format) : DateTime.fromISO(value);

  return dateObject.isValid;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isArrayLikeString(value: any): value is string {
  return typeof value === "string" && value.startsWith("[");
}

export function safeParseJSONtoArray<T>(value: string): T[] {
  try {
    // safety against alreacary parsed array
    if (Array.isArray(value)) {
      return value as T[];
    }

    if (isArrayLikeString(value)) {
      const arr = JSON.parse(value) as T[];
      if (Array.isArray(arr)) {
        return arr as T[];
      }

      return [];
    }
  } catch (e) {
    return [];
  }

  return [];
}

export function safeParseJSON(json: string | null) {
  if (!json) {
    return {};
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}

export function isBooleany(value: any): value is boolean {
  value = !isNil(value) ? value.toString().toLowerCase() : value;
  return value === "true" || value === "false" || value === "yes" || value === "no";
}

export function toBoolean(value: any): boolean {
  value = !isNil(value) ? value.toString().toLowerCase() : value;
  return !!(value === "true" || value === "yes");
}
