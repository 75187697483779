// Own wrapper around useMutation to make it easier to use fetch with react-query
import { type UseMutationOptions, useMutation } from "@tanstack/react-query";

import getCookie from "./getCookie";

interface BasicJSONResponse {
  ok: boolean;
  message?: string;
}

class ErrorWithResponse extends Error {
  responseJSON: Record<string, any>;

  constructor(message: string, responseJSON: Record<string, any>) {
    super(message);
    this.responseJSON = responseJSON;
  }
}

function useFetchMutation<ResponseData, TError, TVariables, TContext>(
  endpoint: string | ((variables: TVariables) => string),
  body: object | ((variables: TVariables) => object),
  mutationOptions: Omit<
    UseMutationOptions<ResponseData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  > = {},
  method = "POST",
) {
  return useMutation<ResponseData, TError, TVariables, TContext>({
    mutationFn: async (variables: TVariables) => {
      return (
        fetch(endpoint instanceof Function ? endpoint(variables) : endpoint, {
          method: method,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify(body instanceof Function ? body(variables) : body),
        }) as unknown as Promise<Response>
      ).then((response) => {
        if (method === "DELETE" && response.status === 204) {
          return response as any; // delete response could be 204 and empty
        }

        if (response.status >= 400) {
          // There could be error message in response
          const defaultErrorMessage = "Something went wrong. Please try again.";
          if (response.status === 400) {
            return response.json().then((responseJSON) => {
              if (responseJSON.message) {
                const error = new ErrorWithResponse(responseJSON.message, responseJSON);
                throw error;
              }

              if (responseJSON?.value?.[0]) {
                const error = new ErrorWithResponse(responseJSON.value[0], responseJSON);
                throw error;
              }

              const error = new ErrorWithResponse(
                Array.isArray(responseJSON) && responseJSON[0] ? responseJSON[0] : defaultErrorMessage,
                responseJSON,
              );
              throw error;
            });
          }

          throw new Error(defaultErrorMessage);
        }

        return response.json() as Promise<ResponseData>;
      });
    },
    ...mutationOptions,
  });
}

// biome-ignore lint/style/noDefaultExport: <ok for hook>
export default useFetchMutation;
export type { BasicJSONResponse };
export { ErrorWithResponse };
