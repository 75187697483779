import { useCreateToast } from "@hooks/useCreateToast";
import useGlobalVariables from "@hooks/useGlobalVariables";
import type SerializedAlert from "@interfaces/SerializedAlert";
import { type UseQueryOptions, keepPreviousData, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation from "@utils/useFetchMutation";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import alertsTableState, {
  type AlertsTableState,
  clearSelection,
  useAlertsTableFiltersValue,
  useAlertsTableStateSnapshot,
} from "../state/alertsTable";

const alertsUrl = () => "/api/v1/alerts/";

type AlertsApiResponse = {
  alerts: {
    count: number;
    next: string | null;
    previous: string | null;
    results: SerializedAlert[];
  };
  filter_boundaries: {
    min_date: string;
    max_date: string;
  };
};

const DEFAULT_PER_PAGE = 25;
async function fetchAlerts<T = AlertsApiResponse>(
  alertsTableStateSnap?: AlertsTableState,
  unseen?: boolean,
): Promise<T> {
  let urlEndpoint = `${alertsUrl()}?`;

  if (alertsTableStateSnap?.perPage) {
    urlEndpoint += `per_page=${alertsTableStateSnap.perPage ? alertsTableStateSnap.perPage : DEFAULT_PER_PAGE}&`;
  }

  if (alertsTableStateSnap?.page) {
    urlEndpoint += `page=${alertsTableStateSnap.page ? alertsTableStateSnap.page + 1 : 1}&`;
  }

  if (alertsTableStateSnap?.filters) {
    const { filters } = alertsTableStateSnap;

    if (filters.object_id.length > 0) {
      const object_ids = filters.object_id.join(",");
      urlEndpoint += `filter{object_id.in}=${object_ids}&`;
    }

    if (filters.severity?.length > 0) {
      const severities = filters.severity.join(",");
      urlEndpoint += `filter{severity.in}=${severities}&`;
    }

    if (filters.created_at) {
      let dates: string[] = [];
      if (filters.created_at.length >= 2) {
        // it could be the case that two dates are the same, so we need to add 1 day to the second date
        const firstDate = DateTime.fromISO(filters.created_at[0].toISOString());
        const secondDate = DateTime.fromISO(filters.created_at[1].toISOString());
        dates = [firstDate.startOf("day").toJSDate().toISOString(), secondDate.endOf("day").toJSDate().toISOString()];
      } else if (filters.created_at.length === 1) {
        const date = DateTime.fromISO(filters.created_at[0].toISOString());
        dates = [date.startOf("day").toJSDate().toISOString(), date.endOf("day").toJSDate().toISOString()];
      }
      if (dates.length > 0) {
        urlEndpoint += `filter{created_at.range}=${dates.join(",")}&`;
      }
    }
  }

  if (unseen) {
    urlEndpoint += "isUnseenOnly=true&";
  }

  return fetchWithSession(urlEndpoint);
}

const ALERTS_CACHE_KEY = (
  currentUserID: number,
  perPage?: number,
  page?: number,
  filters?: AlertsTableState["filters"],
) => ["alerts", currentUserID, perPage, page, filters];

function useAlerts<TSelectedData = AlertsApiResponse>(
  options: Partial<UseQueryOptions<AlertsApiResponse, unknown, TSelectedData>> = {},
) {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();
  const alertsTableStateSnap = useAlertsTableStateSnapshot();

  const query = useSuspenseQuery({
    queryKey: ALERTS_CACHE_KEY(
      currentUserID,
      alertsTableStateSnap.perPage,
      alertsTableStateSnap.page,
      alertsTableStateSnap.filters,
    ),
    queryFn: () => fetchAlerts(alertsTableStateSnap),
    initialData: keepPreviousData,
    ...options,
  });

  useEffect(() => {
    if (query.isSuccess) {
      // update the unseen alerts count since they are already seen without extra fetches
      queryClient.setQueryData(UNSEEN_ALERTS_CACHE_KEY(currentUserID), 0);
    }
  }, [query.isSuccess, queryClient, currentUserID]);

  return query;
}

function useDeleteAlerts() {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();
  const alertsTableStateSnap = useSnapshot(alertsTableState);
  const { addMessage } = useCreateToast();
  const filtersStateSnap = useAlertsTableFiltersValue();

  return useFetchMutation<void, Error, number[], unknown>(
    `${alertsUrl()}batch_delete/`,
    (data: number[]) => {
      // The request should include a JSON payload containing an array of IDs to delete, like this: {"ids": [1, 2, 3]}.
      return {
        ids: data,
      };
    },
    {
      onSuccess: async () => {
        clearSelection();

        await queryClient.invalidateQueries({
          queryKey: ALERTS_CACHE_KEY(
            currentUserID,
            alertsTableStateSnap.perPage,
            alertsTableStateSnap.page,
            filtersStateSnap,
          ),
        });

        addMessage({
          title: "Alerts deleted",
          content: "Selected alerts have been deleted.",
          variant: "success",
        });
      },
    },
    "POST",
  );
}

function useAlert(id: number, options?: UseQueryOptions<SerializedAlert, unknown>) {
  const { currentUserID } = useGlobalVariables();
  const [detailsPage, setDetailsPage] = useState<number>(0);

  const { data, isLoading, isFetching } = useSuspenseQuery({
    queryKey: ["alerts", currentUserID, id, detailsPage],
    queryFn: async () => await fetchWithSession(`${alertsUrl() + id}/?details_page=${detailsPage + 1}`),
    initialData: keepPreviousData,
    ...options,
  });

  return { data, isLoading, detailsPage, setDetailsPage, isFetching };
}

const UNSEEN_ALERTS_CACHE_KEY = (currentUserID: number) => ["alerts", currentUserID, "unseen"];

export default useAlerts;
export type { AlertsApiResponse };
export { useDeleteAlerts, useAlert, fetchAlerts, UNSEEN_ALERTS_CACHE_KEY };
