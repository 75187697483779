import type React from "react";
import { Suspense, createContext, useEffect, useRef } from "react";
import { proxy } from "valtio";

import { RecordsType, useClosestListingTableState, useColumnConfiguration, useThreatsListing } from "@features/listing";
import { type ListingTableProps, ListingTableState } from "@features/listing/ListingTableState";

import TableInstance from "./ListingTable/TableInstance";
import TableLoader from "./ListingTable/TableLoader";
// import TableLoader from "./ListingTable/TableLoader";
import TableTopControls from "./ListingTable/TableTopControls";

function useRecords(snap: Readonly<ListingTableState>) {
  return useThreatsListing(snap.urlEndpoint, {
    suspense: true,
    useErrorBoundary: false,
  });
}

function TableInstanceContainer() {
  const { listingTableSnap } = useClosestListingTableState();

  if (listingTableSnap.dataError) {
    return (
      <div
        className="flex relative flex-col flex-1 px-4 py-3 m-4 h-full text-orange-500 bg-orange-50 rounded border border-orange-400"
        role="alert"
      >
        <strong className="block font-bold">Query validation error!</strong>
        <span className="block sm:inline">{listingTableSnap.dataError}</span>
      </div>
    );
  }

  if (listingTableSnap.isEnabled === false) {
    return (
      <div className="relative px-4 py-3 m-4 bg-yellow-50 rounded border border-yellow-400">
        <span className="block text-sm text-yellow-800 sm:inline">
          Enter a search query to preview matching records.
        </span>
      </div>
    );
  }

  if (listingTableSnap.results === undefined || listingTableSnap.isLoading) {
    return <TableLoader />;
  }

  return <TableInstance />;
}

/**
 * Synchronizes the table state with the data fetched from the API.
 * It handles the data updates, error handling, loading state, and pagination.
 *
 * Usage:
 * - Place the `TableStateDataSync` component within the `ListingTable` component hierarchy.
 * - It requires access to the `ListingTableContext` to update the table state.
 * - There should be only one `TableStateDataSync` component in the component tree per nested ListingTableState context.
 */
export function TableStateDataSync() {
  const { listingTableSnap, listingTableState } = useClosestListingTableState();

  const visibleColumns = listingTableState.useVisibleColumns();

  const columnsConfiguration = useColumnConfiguration(visibleColumns, listingTableSnap.recordsType);

  // sync the table state with records query execution
  const { data, error, isFetching, isLoading } = useRecords(listingTableSnap);

  // sync the table state with records query execution
  useEffect(() => {
    listingTableState.onDataUpdate({
      isFetching,
      dataError: error?.message,
      isLoading,
      isEnabled: true,
      results: data?.results,
      total_count: data?.total_count,
      bookmarked_count: data?.bookmarked_count,
      total_pages: data?.meta?.total_pages ?? 0,
    });
  }, [data, isFetching, isLoading, error, listingTableState.onDataUpdate]);

  useEffect(() => {
    listingTableState.updateColumnsConfiguration(columnsConfiguration);
  }, [columnsConfiguration, listingTableState.updateColumnsConfiguration]);

  useEffect(() => {
    // todo: update in internal method and compare with the current state
    if (visibleColumns?.length > 0) {
      listingTableState.visibleColumns = visibleColumns;
    }
  }, [visibleColumns, listingTableState]);

  return null;
}

function ListingTableInner({ topControls }: { topControls?: React.ReactNode }) {
  return (
    <Suspense fallback={<div className="animate-pulse w-full h-full min-h-[600px] bg-gray-100">&nbsp;</div>}>
      {topControls}
      <TableInstanceContainer />
    </Suspense>
  );
}

function ListingTable({ topControls = <TableTopControls /> }: { topControls?: React.ReactNode }) {
  return <ListingTableInner topControls={topControls} />;
}

const ListingTableContext = createContext<ListingTableState>(undefined!);

const ListingTableProvider = ({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: ListingTableProps;
}) => {
  const localWidgetState = useRef(proxy<ListingTableState>(new ListingTableState(initialState))).current;

  return <ListingTableContext.Provider value={localWidgetState}>{children}</ListingTableContext.Provider>;
};

export default ListingTable;

export { ListingTableProvider, ListingTableContext };
