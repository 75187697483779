import useGlobalVariables from "@hooks/useGlobalVariables";
import type SerializedOrganization from "@interfaces/SerializedOrganization";
import type SerializedProfile from "@interfaces/SerializedProfile";
import { type QueryKey, type UseQueryOptions, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import fetchWithSession from "@utils/fetchWithSession";
import useFetchMutation from "@utils/useFetchMutation";

// TODO: move initial data here into server-side rendering
const clientSettingsUrlEndpoint = (userID: number, isGET = true) =>
  `/api/v1/profiles/${userID}/${
    isGET
      ? "?include[]=client_settings&include[]=organization_configuration&include[]=user_data&include[]=organization"
      : ""
  }`;

const fetchProfile = async (currentUserID: number) => {
  return fetchWithSession(clientSettingsUrlEndpoint(currentUserID));
};

interface UserProfileResponse {
  profile: SerializedProfile;
  organizations?: SerializedOrganization[];
  client_settings?: any;
}

const PROFILE_QUERY_KEY = (currentUserID: number) => ["userProfile", currentUserID];

const useCurrentUserProfileSelect = <T>({
  select,
  options,
}: {
  select: (userProfileResponse: UserProfileResponse) => T;
  options?: Partial<UseQueryOptions<T, Error, T, (string | number)[]>>;
}) => {
  const { currentUserID } = useGlobalVariables();
  // @ts-ignore
  return useQuery({
    queryKey: PROFILE_QUERY_KEY(currentUserID),
    queryFn: async () => fetchProfile(currentUserID),
    staleTime: 1 * 60 * 1000,
    ...options,
    enabled: !!currentUserID,
    // @ts-ignore
    select,
  });
};

function useMutateCurrentUserProfile() {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();

  return useFetchMutation<UserProfileResponse, Error, Partial<SerializedProfile>, unknown>(
    clientSettingsUrlEndpoint(currentUserID, false),
    (partialProfile) => partialProfile,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(PROFILE_QUERY_KEY(currentUserID), data);
      },
    },
    "PATCH",
  );
}

function useCurrentUserProfile(options?: UseQueryOptions<UserProfileResponse, unknown, UserProfileResponse, QueryKey>) {
  const { currentUserID } = useGlobalVariables();
  return useSuspenseQuery({
    queryKey: PROFILE_QUERY_KEY(currentUserID),
    queryFn: () => fetchProfile(currentUserID),
    staleTime: 1 * 60 * 1000,
    ...options,
  });
}

export {
  useCurrentUserProfile,
  useCurrentUserProfileSelect,
  PROFILE_QUERY_KEY,
  clientSettingsUrlEndpoint,
  useMutateCurrentUserProfile,
};

export type { UserProfileResponse };
