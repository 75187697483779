import { sortBy } from "lodash-es";
import { proxy, subscribe, useSnapshot } from "valtio";

import { RecordsType } from "@features/listing";
import { safeParseJSON } from "@utils/utility";

interface SearchHistoryItem {
  id: number;
  query: string;
}

interface SearchHistoryState {
  [RecordsType.Malware]: {
    records: SearchHistoryItem[];
  };
  [RecordsType.CVEs]: {
    records: SearchHistoryItem[];
  };
}

const defaultSearchHistoryState = {
  [RecordsType.Malware]: {
    records: [],
  },
  [RecordsType.CVEs]: {
    records: [],
  },
};

const loadSearchHistory = () => {
  const searchHistory = safeParseJSON(localStorage.getItem(StorageKey));
  if (searchHistory?.[RecordsType.Malware]?.records && searchHistory?.[RecordsType.CVEs]?.records) {
    return searchHistory;
  }

  return defaultSearchHistoryState;
};

const StorageKey = "searchHistoryState";
const SEARCH_HISTORY_LIMIT = 8;
const searchHistoryState = proxy<SearchHistoryState>(loadSearchHistory());

subscribe(searchHistoryState, () => {
  localStorage.setItem(StorageKey, JSON.stringify(searchHistoryState));
});

function addSearchHistoryItem(recordsType: RecordsType, searchQuery: string) {
  if (searchQuery === "") {
    return;
  }

  // we clean the search history to avoid duplicates (search will be shown as most recent)
  removeSearchHistoryItemByQuery(recordsType, searchQuery);

  const item: SearchHistoryItem = {
    id: Date.now(),
    query: searchQuery,
  };

  let updatedRecords = [...searchHistoryState[recordsType].records, item];
  // remove the oldest item if we have reached the limit

  if (updatedRecords.length >= SEARCH_HISTORY_LIMIT) {
    updatedRecords = updatedRecords.slice(1);
  }

  searchHistoryState[recordsType].records = updatedRecords;
}

function removeSearchHistoryItem(recordsType: RecordsType, id: number) {
  searchHistoryState[recordsType].records = [
    ...searchHistoryState[recordsType].records.filter((item) => item.id !== id),
  ];
}

function removeSearchHistoryItemByQuery(recordsType: RecordsType, query: string) {
  searchHistoryState[recordsType].records = [
    ...searchHistoryState[recordsType].records.filter((item) => item.query !== query),
  ];
}

function useSearchHistory(recordsType: RecordsType) {
  const searchHistory = useSnapshot(searchHistoryState);
  // sort by timestamp
  return sortBy(searchHistory?.[recordsType]?.records, "id").reverse();
}

export { useSearchHistory, addSearchHistoryItem, removeSearchHistoryItem };

export type { SearchHistoryState, SearchHistoryItem };
