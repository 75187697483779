import React from "react";

import { RecordsType } from "@features/listing";
import useOrganizationsConfiguration from "@hooks/useOrganizationsConfiguration";
import { SerializedMandiantMalwareKeys } from "@interfaces/SerializedMandiantMalware";
import useIntrospections from "@queries/useIntrospections";

// todo: We need to sort out usage of useVisibleColumns and useAvailableColumns hooks to grab columns, introspections and available fields
// Returns available threat columns by introspections
function useAvailableColumns(recordsType: RecordsType) {
  const { data: introspections } = useIntrospections();
  const { data: organizationData } = useOrganizationsConfiguration();

  return React.useMemo(() => {
    if (recordsType === RecordsType.Malware) {
      return SerializedMandiantMalwareKeys;
    }

    // vip_id is always shown
    // Filter out vip_intels field since its always included into vip_id rendering
    // Filter out vip_favorite field since its always included into vip_id rendering
    const fieldsToExclude = ["vip_id", "cve_threat_intel_id", "vip_intels", "vip_favorite"];

    if (!organizationData.are_custom_risk_levels_enabled) {
      fieldsToExclude.push("vip_risk_level");
    }

    const availableColumns = Object.keys(introspections.fields)
      .map((key) => key)
      .filter((field) => !fieldsToExclude.includes(field));

    return availableColumns;
  }, [introspections, recordsType, organizationData]);
}

export default useAvailableColumns;
