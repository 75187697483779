import type React from "react";
import { useState } from "react";
import { type DefaultValues, type FieldValues, type UseFormReturn, useForm } from "react-hook-form";

import AlertDialog from "./AlertDialog";
import Button from "./Button";

type FormConfig<T extends FieldValues> = {
  defaultValues: DefaultValues<T>;
  render: (form: UseFormReturn<T>) => React.ReactNode;
};

type CallbackProps<F extends object = Record<string, any>> = {
  formData?: F;
} & Record<string, any>;

type WithConfirmationProps<T extends object, F extends object = Record<string, any>> = React.ComponentProps<
  React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>
> &
  T & { callbackProps?: CallbackProps<F> };

type DialogConfig = {
  title: string;
  description: string;
};

function withConfirmation<T extends object, F extends object = Record<string, any>>(
  TriggerComponent: React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>,
  dialogConfig: DialogConfig,
  callback: (callbackProps?: CallbackProps<F>) => any,
  formConfig?: FormConfig<F>,
) {
  return (props: WithConfirmationProps<T, F>) => {
    const [isOpen, setIsOpen] = useState(false);
    const form = useForm<F>(formConfig ? { defaultValues: formConfig.defaultValues } : undefined);

    const { callbackProps, ...rest } = props;
    const handleConfirm = (formData?: F) => {
      callback({ ...callbackProps, formData });
      setIsOpen(false);
    };

    return (
      <AlertDialog
        Trigger={TriggerComponent}
        triggerProps={rest}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        title={dialogConfig.title}
        description={dialogConfig.description}
      >
        {formConfig && (
          <form onSubmit={form.handleSubmit(handleConfirm)} className="mt-4 mb-4">
            {formConfig.render(form)}
          </form>
        )}

        <div className="flex justify-end gap-4 mt-2">
          <AlertDialog.Cancel asChild>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <Button color="danger" onClick={form.handleSubmit(handleConfirm)}>
              Confirm
            </Button>
          </AlertDialog.Action>
        </div>
      </AlertDialog>
    );
  };
}

export default withConfirmation;
