import classNames from "classnames";
import React, { useState } from "react";

import { ChevronDownIcon, CommandLineIcon, DocumentIcon, TagIcon } from "@heroicons/react/24/outline";
import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from "@material-tailwind/react";
import { useCurrentUserProfile } from "@queries/useCurrentUserProfile";

const adminMenuItems = [
  {
    label: "Admin Portal",
    icon: CommandLineIcon,
    url: "/admin/",
  },
  {
    label: "Global Tags",
    icon: TagIcon,
    url: "/manage_global_tags/",
  },
  {
    label: "Logs",
    icon: DocumentIcon,
    url: "/logs/",
  },
];

function AdminMenu() {
  const { data: userProfile } = useCurrentUserProfile();
  const isAdmin = userProfile?.profile?.is_staff;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  if (!isAdmin) {
    return null;
  }
  const closeMenu = () => setIsMenuOpen(false);
  if (!isAdmin) {
    return null;
  }

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          size="lg"
          color="blue"
          className="lg:ml-auto sm:flex items-center hidden h-10 gap-1 py-1 pl-3 pr-4 text-xs rounded"
          ripple={false}
        >
          Admin
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-0">
        {adminMenuItems.map(({ label, icon, url }, key) => {
          return (
            <Typography key={label} as="a" href={url} variant="small">
              <MenuItem
                key={label}
                onClick={closeMenu}
                className={classNames(
                  "flex gap-2 rounded",
                  "hover:bg-blue-500 focus:bg-gray-300 active:bg-gray-300 hover:text-gray-300 transition-colors",
                )}
              >
                {React.createElement(icon, {
                  className: "h-5 w-5",
                  strokeWidth: 2,
                })}
                <Typography as="span" variant="small" className="font-semibold" color={"inherit"}>
                  {label}
                </Typography>
              </MenuItem>
            </Typography>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default AdminMenu;
