import { useEffect, useState } from "react";

const contextElementID = "page-context"; // exists in main templates scripts.html

function usePageContext<T = any>() {
  const [pageContext, setPageContext] = useState<T | null>(getPageContext<T>());

  useEffect(() => {
    const pageContextElement = document.getElementById(contextElementID)!; // exists in main templates scripts.html, so it's safe to use !
    const textContent = pageContextElement.textContent;
    if (textContent) {
      try {
        const _pageContext = JSON.parse(textContent) as T;
        setPageContext(_pageContext);
      } catch (e) {
        throw new Error("Failed to parse page context");
      }
    }
  }, []);

  return pageContext as T;
}

function getPageContext<T = any>() {
  const pageContextElement = document.getElementById(contextElementID)!; // exists in main templates scripts.html, so it's safe to use !
  let pageContext = pageContextElement.textContent;
  if (pageContext) {
    try {
      pageContext = JSON.parse(pageContext);
    } catch (e) {
      throw new Error("Failed to parse page context");
    }
  }

  return pageContext as T;
}

export default usePageContext;
export { getPageContext };
