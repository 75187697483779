import { DateTime } from "luxon";
import React from "react";

import Link from "@components/Link";
import Tooltip from "@components/Tooltip";
import useOrganizationsConfiguration from "@hooks/useOrganizationsConfiguration";
import useSiteSettings from "@queries/useSiteSettings";

function TierNotifyBanner() {
  const { data: organizationConfiguration } = useOrganizationsConfiguration();
  const { data: siteSettings } = useSiteSettings();
  const supportEmail = siteSettings.default_support_email;

  const tier = organizationConfiguration.tier;

  if (tier === "FREE") {
    return (
      <div
        className={"hidden sm:block px-3 py-2 text-xs rounded-lg md:mr-2 border border-1 border-gray-300 cursor-help"}
      >
        <Tooltip
          value={
            <>
              Want to upgrade?{" "}
              <Link href={`mailto:${supportEmail}`} size="small" className="underline-offset-2 underline">
                Contact support
              </Link>
            </>
          }
        >
          <div className="flex flex-row items-center justify-center gap-1">
            <div className="border-1 flex items-center justify-center w-6 h-6 text-xs text-gray-500 border border-gray-500 rounded-full">
              <i className="fas fa-bolt-lightning" />
            </div>
            <span className="text-xs font-semibold text-gray-500">Free</span>
          </div>
        </Tooltip>
      </div>
    );
  }

  if (!organizationConfiguration.trial_expiration_date || tier === "PAID") {
    return null;
  }

  const trialEndDate = DateTime.fromFormat(organizationConfiguration.trial_expiration_date, "yyyy-MM-dd");
  const now = DateTime.now();
  const daysLeft = Math.ceil(
    trialEndDate.diff(now, "days", {
      conversionAccuracy: "longterm",
    }).days,
  );

  if (daysLeft < 0) {
    return null; // or render a different message indicating the trial has expired
  }

  return (
    <div
      className={`hidden sm:block px-6 py-3 text-xs rounded-lg md:mr-2 text-gray-600 ${
        daysLeft <= 3 ? "bg-orange-100 " : "bg-gray-200"
      }`}
    >
      <span>
        {daysLeft} {`day${daysLeft > 1 ? "s" : ""}`} left in trial.{" "}
      </span>
      <Link href={`mailto:${supportEmail}`} size="small" className="underline-offset-2 underline">
        Contact support
      </Link>
    </div>
  );
}

export default TierNotifyBanner;
