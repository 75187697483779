import "vite/modulepreload-polyfill";

// Purpose: make valtio state type use object instead of readonly object to gradually use the state without type declaration for every snapshot
declare module "valtio" {
  function useSnapshot<T extends object>(p: T): T;
}

// import "./styles.css";
import "react-tooltip/dist/react-tooltip.css";

import "./styles/flatpickr.css";


import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { browserTracingIntegration, init, replayIntegration } from "@sentry/react";

import Router from "./Router";

if (process.env.SENTRY_DSN_FRONTEND && process.env.MODE !== "development") {
  const environment = process.env.SENTRY_ENVIRONMENT ?? "production";
  init({
    release: `nucleus-vip@${process.env.APP_VERSION}`,
    dsn: process.env.SENTRY_DSN_FRONTEND,
    environment: environment,
    tracesSampleRate: Number.parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE ?? "0.3"),
    replaysSessionSampleRate: Number.parseFloat(process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? "0.3"),
    replaysOnErrorSampleRate: Number.parseFloat(process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? "1.0"),
    integrations: environment !== "development" ? [browserTracingIntegration(), replayIntegration()] : undefined,
  });
}

console.info(`Nucleus VIP version: ${process.env.APP_VERSION}`);

const domContainer = document.getElementById("react-root");
const root = createRoot(domContainer!);
root.render(
  // TODO: enable StrictMode when we have a chance to fix NucleusQL double initialization issue
  // <StrictMode>
  <Router />,
  // </StrictMode>,
);
