import { DEFAULT_MALWARE_COLUMNS, RecordsType } from "@features/listing";
import type { ClientSettings } from "@interfaces/SerializedProfile";
import {
  PROFILE_QUERY_KEY,
  type UserProfileResponse,
  clientSettingsUrlEndpoint,
  useCurrentUserProfileSelect,
} from "@queries/useCurrentUserProfile";
import { useQueryClient } from "@tanstack/react-query";
import useFetchMutation from "@utils/useFetchMutation";

import useGlobalVariables from "./useGlobalVariables";

function migrateToV2(settings: ClientSettings): ClientSettings {
  // Implement migration logic here, for example:
  return {
    ...settings,
    _v: 2,
    visible_columns: {
      [RecordsType.CVEs]: settings.visible_columns as any, // cast to any, since its outdated version of settings
      [RecordsType.Malware]: [...DEFAULT_MALWARE_COLUMNS],
    },
  };
}

const LAST_V = 2;

function useClientSettings() {
  return useCurrentUserProfileSelect<ClientSettings>({
    select: (profileApiResponse) => {
      const { profile } = profileApiResponse;
      const { client_settings, organization_configuration } = profile || {
        client_settings: {},
        organization_configuration: { default_columns: [] },
      };

      // ideally we need some validation here, as well as migrations for old settings
      if (client_settings && Object.keys(client_settings).length) {
        if (!client_settings._v) {
          return migrateToV2(client_settings);
        }

        return client_settings;
      }

      // use defaults
      return {
        visible_columns: {
          CVEs: organization_configuration.default_columns,
          Malware: [...DEFAULT_MALWARE_COLUMNS],
        },
        pinned_searches: {},
        _v: LAST_V,
      } as ClientSettings;
    },
  });
}

// I assume ideally we may need to get rid of direct mutation for client settings and use subfunctions
function useMutateClientSettings() {
  const queryClient = useQueryClient();
  const { currentUserID } = useGlobalVariables();
  const { data: clientSettings } = useClientSettings();

  return useFetchMutation<UserProfileResponse, Error, Partial<ClientSettings>, unknown>(
    clientSettingsUrlEndpoint(currentUserID, false),
    (partialClientSettings) => {
      // merge partial settings with existing settings
      // merge settings sub-folders manually, as we dont want old values to be kept, f.e. partialClientSettings may have only visible_columns, but we want to keep pinned_searches from previous settings, but also need to replace visible_columns previous value with new one
      const updatedClientSettings = {
        ...clientSettings,
        ...partialClientSettings,
      };

      return {
        client_settings: updatedClientSettings,
      };
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(PROFILE_QUERY_KEY(currentUserID), data);
      },
    },
    "PATCH",
  );
}

function useClientPinnedSearches(): {
  [x: string]: string;
} {
  const {
    data: { pinned_searches: pinnedSearches = {} } = {},
  } = useClientSettings();

  // transform object values to strings, since after 2.16.0 version we store pinned searches as strings, but older records may be numbers
  const mutatedPinnedSearches = { ...pinnedSearches } as {
    [x: string]: string;
  };

  for (const [key, value] of Object.entries(mutatedPinnedSearches)) {
    mutatedPinnedSearches[key] = value.toString();
  }

  return mutatedPinnedSearches;
}

export { useMutateClientSettings, useClientPinnedSearches };

export default useClientSettings;
