import cx from "classnames";
import type React from "react";
import { Fragment } from "react";

import { Transition } from "@headlessui/react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";

const AlertDialog = ({
  Trigger,
  title,
  description,
  isOpen,
  onOpenChange,
  children,
  triggerProps,
}: {
  Trigger: React.ForwardRefExoticComponent<React.RefAttributes<HTMLElement>>;
  title?: string;
  description?: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  triggerProps?: React.ComponentProps<typeof Trigger>;
}) => {
  return (
    <AlertDialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogPrimitive.Trigger asChild>
        <Trigger {...triggerProps} />
      </AlertDialogPrimitive.Trigger>
      <Transition.Root show={isOpen}>
        <AlertDialogPrimitive.Portal forceMount>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <AlertDialogPrimitive.Overlay forceMount className="fixed inset-0 z-20 bg-black/50" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <AlertDialogPrimitive.Content
              forceMount
              className={cx(
                "fixed z-[999]",
                "w-[95vw] max-w-md rounded-lg p-4 md:w-full",
                "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
                "bg-white dark:bg-gray-800",
                "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
              )}
            >
              {title && (
                <AlertDialogPrimitive.Title className="dark:text-gray-100 text-sm font-semibold text-gray-900">
                  {title}
                </AlertDialogPrimitive.Title>
              )}
              {description && (
                <AlertDialogPrimitive.Description className="dark:text-gray-400 mt-2 text-sm font-normal text-gray-700">
                  {description}
                </AlertDialogPrimitive.Description>
              )}
              {children}
            </AlertDialogPrimitive.Content>
          </Transition.Child>
        </AlertDialogPrimitive.Portal>
      </Transition.Root>
    </AlertDialogPrimitive.Root>
  );
};

AlertDialog.Action = AlertDialogPrimitive.Action;
AlertDialog.Cancel = AlertDialogPrimitive.Cancel;

export default AlertDialog;
