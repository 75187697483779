export enum FIELD_TYPE {
  JSON = "json",
  STRING = "str",
  INTEGER = "int",
  FLOAT = "float",
  BOOLEAN = "boolean",
  DATE = "date",
  DATETIME = "datetime",

  // custom types
  NOTES = "notes",
  FAVORITE = "favorite",
  INTELS = "feeds",
  TAGS = "tags",
  MONITORED = "monitored",
  RISK_LEVEL = "risk_level",
  COMPUTED = "computed",
}

export function setUrlParams(url: string, params: Record<string, string>) {
  const parts = url.split("?");
  const path = parts[0];
  let queryString = parts.slice(1).join("?");
  const pairs = queryString.split("&");
  let pair;
  let key;
  let value;
  let i;

  Object.keys(params).forEach((k) => {
    key = encodeURI(k);
    value = encodeURI(params[key]);
    i = pairs.length;
    while (i--) {
      pair = pairs[i].split("=");
      if (pair[0] === key) {
        pair[1] = value;
        pairs[i] = pair.join("=");
        break;
      }
    }
    if (i < 0) {
      pairs.push(`${key}=${value}`);
    }
  });
  queryString = pairs.join("&");
  return queryString ? [path, queryString].join("?") : path;
}

export function escapeRegExp(str: string) {
  // http://stackoverflow.com
  // /questions/3446170/escape-string-for-use-in-javascript-regex
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
