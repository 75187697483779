import type SerializedOrganizationConfiguration from "@interfaces/SerializedOrganizationConfiguration";
import { useCurrentUserProfileSelect } from "@queries/useCurrentUserProfile";

// would be nice to have a way to specify the return type of the select function
function useOrganizationsConfiguration() {
  const profileSelectQuery = useCurrentUserProfileSelect<SerializedOrganizationConfiguration>({
    select: (profileApiResponse) => {
      const { profile } = profileApiResponse;
      const { organization_configuration } = profile || {};

      return organization_configuration;
    },
  });

  return { ...profileSelectQuery, data: profileSelectQuery?.data ?? {} };
}

function useOrganizationTier() {
  const { data: organizationData } = useOrganizationsConfiguration();

  return organizationData?.tier;
}

export default useOrganizationsConfiguration;
export { useOrganizationTier };
