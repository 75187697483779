import { debounce } from "lodash-es";
import React, { useEffect, useMemo } from "react";
import Select, { type StylesConfig } from "react-select";

const colourStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    fontSize: "10px",
    borderColor: "#e2e8f0",
    minHeight: "40px",
  }),
  option: (styles) => {
    return {
      ...styles,
      padding: "2px 6px",
    };
  },
  valueContainer: (styles) => ({
    ...styles,
    padding: "0 6px",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      padding: "0",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    padding: "0",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ":hover": {
      color: "white",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    fontSize: "10px",
    padding: 0,
  }),
};

function SelectionFilter({
  header,
  accessorKey,
  options,
  filteredOptions,
  currentValue,
  updateFilterState,
}: {
  header?: string;
  accessorKey: string;
  options: { label: string; value: string }[];
  filteredOptions: { label: string; value: string }[];
  currentValue: string[];
  updateFilterState: (value: string[]) => void;
}) {
  const [filterValue, setFilterValue] = React.useState(
    currentValue?.length ? currentValue.map((v) => ({ label: v, value: v })) : [],
  );

  // debounce the filter change into the table state
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const debouncedSetFilters = useMemo(
    () =>
      debounce((newFilterValue) => {
        updateFilterState(newFilterValue.map((v: { label: string; value: string }) => v.value));
      }, 800),
    [],
  );

  // sync the table state with the local state
  useEffect(() => {
    debouncedSetFilters(filterValue);
  }, [filterValue, debouncedSetFilters]);

  // Update the state when input changes
  const handleFilterChange = (selectedOptions: { label: string; value: string }[]) => {
    setFilterValue(selectedOptions);
  };

  // if we have no filters applied to this column, show filteredOptions, otherwise show all the filters so it would be possible to add more
  const optionsToShow = currentValue.length === 0 ? filteredOptions : options;
  return (
    <div>
      <label htmlFor={accessorKey} className="block mb-1">
        {(header ?? accessorKey).split("_").join(" ")}
      </label>
      <div className="relative">
        <i className="fas fa-filter left-2 top-1/2 absolute transform -translate-y-1/2" />
        <Select
          placeholder="All"
          id={accessorKey}
          value={filterValue}
          onChange={handleFilterChange}
          options={optionsToShow}
          isMulti
          styles={colourStyles}
        />
      </div>
    </div>
  );
}

export default SelectionFilter;
