import React, { lazy, Suspense } from "react";

import NucleusNavbar from "@components/NucleusNavbar";
import useLocation from "@hooks/useLocation";
import TemplateSlot from "@utils/TemplateSlot";

import { NucleusQLProvider } from "@components/NucleusQL/NucleusQLProvider";
import QueryErrorBoundary from "@components/QueryErrorBoundary";
import { ListingTableWrapper } from "./ListingTableWrapper";
import Portals from "./Portals";
import Providers from "./Providers";

const IndexPage = lazy(() => import("./pages/index"));
const AnalyzePage = lazy(() => import("./pages/listing"));
const MalwarePage = lazy(() => import("./pages/malware"));
const VulnerabilityPage = lazy(() =>
  import("./pages/vulnerability_details").then((module) => ({ default: module.VulnerabilityDetailsPage })),
);
const MyOrganizationPage = lazy(() => import("./pages/my_organization"));
const StorePage = lazy(() => import("./pages/store"));
const ProfilePage = lazy(() => import("./pages/profile"));
const WatchingPage = lazy(() => import("./pages/watching"));
const AlertsPage = lazy(() => import("./pages/alerts").then((module) => ({ default: module.AlertsPage })));
const LoginPage = lazy(() => import("./pages/login").then((module) => ({ default: module.LoginPage })));
const SourcesPage = lazy(() => import("./pages/sources"));
const GlobalTagsPage = lazy(() => import("./pages/global_tags").then((module) => ({ default: module.GlobalTagsPage })));

const routes = [
  { path: /^\/$/, component: IndexPage },
  { path: /^\/analyze\/?$/, component: AnalyzePage },
  { path: /^\/malware\/?$/, component: MalwarePage },
  { path: /^\/v\/(.*)\/?$/, component: VulnerabilityPage },
  { path: /^\/organization\/?$/, component: MyOrganizationPage },
  { path: /^\/integrations\/?$/, component: StorePage },
  { path: /^\/profile\/?$/, component: ProfilePage },
  { path: /^\/monitoring\/?$/, component: WatchingPage },
  { path: /^\/monitoring\/alerts\/?$/, component: AlertsPage },
  { path: /^\/login\/?$/, component: LoginPage },
  { path: /^\/sources\/?$/, component: SourcesPage },
  { path: /^\/manage_global_tags\/?$/, component: GlobalTagsPage },
];

// grab this feature flag from url param
const showInsights = new URLSearchParams(window.location.search).get("showInsights") === "true";

function Router() {
  const currentPath = useLocation();

  // Find the first matching route
  const route = routes.find(({ path }) => path.test(currentPath));

  // Determine which component to render
  let PageComponent = null;
  if (route) {
    // Temporal hardcode for the home page
    if (route.path.test("/") && !showInsights) {
      PageComponent = routes.find(({ path }) => path.test("/analyze"))?.component ?? null;
    } else {
      PageComponent = route.component;
    }
  }

  return (
    <Providers>
      <Suspense fallback={<div />}>
        <NucleusQLProvider key="global-search-widget">
          <ListingTableWrapper>
            <Suspense fallback={<div />}>
              <TemplateSlot slotID="slot_navbar" keepContent={false} keepClasses={true}>
                <NucleusNavbar />
              </TemplateSlot>
            </Suspense>
            {PageComponent && (
              <QueryErrorBoundary>
                <Suspense fallback={<div />}>
                  <PageComponent />
                </Suspense>
              </QueryErrorBoundary>
            )}
          </ListingTableWrapper>
        </NucleusQLProvider>
      </Suspense>
      <Portals />
    </Providers>
  );
}

export default Router;
