declare const currentUserID: number; // declared in main template html file and passed from django
declare const ListingAbsoluteUrl: string; // declared in main template html file and passed from django

function useGlobalVariables() {
  return {
    currentUserID: +currentUserID,
    listingUrl: ListingAbsoluteUrl, // This might be wrong, since its resolved as '/' in the template. Might consider using 'GlobalUrl' instead(not yet tested)
  };
}

export default useGlobalVariables;
