import { useFieldReferencesStateSync } from "@features/field_references/useFieldReferencesStateSync";
import { Bars2Icon } from "@heroicons/react/24/outline";
import { useOrganizationTier } from "@hooks/useOrganizationsConfiguration";
import { Collapse, IconButton, Navbar, Typography } from "@material-tailwind/react";
import React, { useState, Suspense } from "react";
import AdminMenu from "./NucleusNavbar/AdminMenu";
import HelpSection from "./NucleusNavbar/HelpSection";
import NavList from "./NucleusNavbar/NavList";
import ProfileMenu from "./NucleusNavbar/ProfileMenu";
import QuickStart from "./NucleusNavbar/QuickStart";
import TierNotifyBanner from "./NucleusNavbar/TierNotifyBanner";

function NucleusNavbar() {
  useFieldReferencesStateSync();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const toggleIsMobileNavOpen = () => setIsMobileNavOpen((cur) => !cur);

  React.useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth >= 960 && setIsMobileNavOpen(false));
  }, []);

  const currentUserOrganizationTier = useOrganizationTier();

  return (
    <Navbar
      className="top w-full md:h-12 max-w-full py-0 px-0 md:py-4 md:px-8 !rounded-none !backdrop-blur-none !shadow-none"
      role="navigation"
      color="transparent"
    >
      <div className="text-blue-gray-900 grow flex items-center justify-between flex-1 h-full">
        <Typography
          as="a"
          href="/"
          className="title-font md:justify-start pr-7 flex items-center justify-center font-medium text-gray-900 border-r border-gray-400"
        >
          <img className="w-10 h-10 p-2" height="40" width="40" src="/static/img/logos/logo.webp" alt="logo" />
          {/* Noto Sans font */}
          <span className="ml-1 text-2xl font-black leading-none text-black" style={{ fontFamily: "Noto Sans" }}>
            VIP
          </span>
        </Typography>
        <div className="lg:block hidden h-full ml-3">
          <Suspense fallback={<div />}>
            <NavList />
          </Suspense>
        </div>
        <div className="grow" />

        <QuickStart />

        {currentUserOrganizationTier && currentUserOrganizationTier !== "PAID" ? <TierNotifyBanner /> : null}

        <React.Suspense fallback={<div />}>
          <HelpSection />
        </React.Suspense>

        <IconButton
          size="sm"
          color="blue"
          variant="text"
          onClick={toggleIsMobileNavOpen}
          className="lg:hidden ml-auto mr-2"
        >
          <Bars2Icon className="w-6 h-6" />
        </IconButton>

        <AdminMenu />
        <ProfileMenu />
      </div>
      <Collapse open={isMobileNavOpen} className="lg:overflow-scroll overflow-hidden">
        <NavList />
      </Collapse>
    </Navbar>
  );
}

export default NucleusNavbar;
